import React, {useState, useEffect, useRef} from 'react';
import {
  Row, Col, Form, Button, Spinner, Overlay, Tooltip,
} from 'react-bootstrap';
import {Link, useNavigate, useLocation} from 'react-router-dom';

import LogoBanner from '../components/LogoBanner';
import Logo from '../components/Logo';
import HIPPA from '../components/HIPPA';
import DisplayModal from '../components/DisplayModal';
import MessageModal from '../components/MessageModal';
import TermsAndConditions from '../components/TermsAndConditions';
import DentalBookPrivacyPolicy from '../components/DentalBookPrivacyPolicy';
import HippaNoticeOfPrivacy from '../components/HippaNoticeOfPrivacy';
import GoogleLoginBtn from '../components/GoogleLoginBtn';
import AppleLoginBtn from '../components/AppleLoginBtn';
import FacebookLoginBtn from '../components/FacebookLoginBtn';

import {useAuth} from '../hooks/AuthContext';

export default function Login() {
  const [termsConditionsShow, setTermsConditionsShow] = useState(false);
  const [privacyPloicyShow, setPrivacyPloicyShow] = useState(false);
  const [errorMessageModalShow, setErrorMessageModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [termsConditionsClicked, setTermsConditionsClicked] = useState(false);
  const [tooltipShow, setTooltipShow] = useState(false);

  const tooltipTarget = useRef(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state ? location.state.from.pathname : '/';

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleTermsConditionsChange = (e) => {
    setTermsConditionsClicked(e.target.checked);
  };

  const showMessage = (message) => {
    setErrorMessage(message);
    setErrorMessageModalShow(true);
  };

  const clickLoginBtn = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    const loginData = {
      login_type: 'native',
      email,
      password,
    };
    const loginResponse = await auth.login((loginData), () => {
      navigate(from, {replace: true});
    });
    if (loginResponse) {
      showMessage(loginResponse.error);
    }
  };

  useEffect(() => {
    auth.getAccountData(() => {
      navigate(from, {replace: true});
    });
  }, [auth]);

  return (
    auth.checkIsLogin ?
      (
        <main>
          <center>
            <LogoBanner />
            <Logo />
            <HIPPA />
            <div className='central-container'>
              <Form validated={validated} onSubmit={clickLoginBtn}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    value={email}
                    onChange={handleChangeEmail}
                    required
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Control
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={handleChangePassword}
                    autoComplete='on'
                    required
                  />
                  <div style={{textAlign: 'right', fontSize: '14px'}}>
                    <Link to='/forget-password'>Forgot Password?</Link>
                  </div>
                </Form.Group>
                <Form.Group style={{fontSize: '14px', textAlign: 'left'}}>
                  <Form.Check type='checkbox' id='check-api-checkbox'>
                    <Form.Check.Input
                      type='checkbox'
                      onChange={handleTermsConditionsChange}
                      required
                    />
                    <Form.Check.Label>
                      I have read and agree to the&nbsp;
                      <span
                        style={{fontSize: '14px', color: '#0d6efd'}}
                        onClick={() => setTermsConditionsShow(true)}
                      >
                        Terms of Service
                      </span>
                      &nbsp;and the&nbsp;
                      <span
                        style={{fontSize: '14px', color: '#0d6efd'}}
                        onClick={() => setPrivacyPloicyShow(true)}
                      >
                        Privacy Policy.
                      </span>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                <Button
                  className='melrose-btn'
                  type='submit'
                >
                  Log in
                </Button>
                <p style={{fontSize: '14px'}}>
                  Don&apos;t have an account?
                  <a href='/signup'>Sign up</a>
                </p>
              </Form>
              <Overlay
                target={tooltipTarget.current}
                show={tooltipShow}
                placement="top"
              >
                <Tooltip id="overlay-example">
                  Please agree to the Terms of Service and the Privacy Policy.
                </Tooltip>
              </Overlay>
              <div
                style={{margin: '2.5rem 0'}}
                ref={tooltipTarget}
                onClick={() => {
                  if (!termsConditionsClicked) {
                    setTooltipShow(!tooltipShow);
                    setTimeout(() => {
                      setTooltipShow(false);
                    }, 3000);
                  }
                }}
              >
                <p> - Log in with - </p>
                <Row>
                  <Col xs={12} className='mb-2'>
                    <GoogleLoginBtn
                      from={from}
                      disabled={termsConditionsClicked}
                    />
                  </Col>
                  <Col xs={12} className='mb-2'>
                    <AppleLoginBtn
                      from={from}
                      disabled={termsConditionsClicked}
                    />
                  </Col>
                  <Col xs={12} className='mb-2'>
                    <FacebookLoginBtn
                      from={from}
                      disabled={termsConditionsClicked}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#0d6efd',
                    fontWeight: '600',
                  }}
                >
                  <a
                    href='mailto:Service@AIDentalBook.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Customer Service: Service@AIDentalBook.com
                  </a>
                </p>
              </div>
            </div>
            <DisplayModal
              show={termsConditionsShow}
              onHide={() => setTermsConditionsShow(false)}
              title={
                'Terms and Conditions of Usage of ' +
                'our Website and Mobile Application'
              }
            >
              <div style={{height: '65vh', overflowX: 'auto'}}>
                <TermsAndConditions />
              </div>
            </DisplayModal>
            <DisplayModal
              show={privacyPloicyShow}
              onHide={() => setPrivacyPloicyShow(false)}
              title='Web And Mobile Privacy Policy'
            >
              <div style={{height: '65vh', overflowX: 'auto'}}>
                <DentalBookPrivacyPolicy />
                <HippaNoticeOfPrivacy />
              </div>
            </DisplayModal>
            <MessageModal
              show={errorMessageModalShow}
              onHide={() => setErrorMessageModalShow(false)}
              textmessage={errorMessage}
            />
          </center>
        </main>
      ) :
      (
        <div className='cover-template'>
          <Spinner animation='border' variant='dark' />
        </div>
      )
  );
}
